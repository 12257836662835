import React, { useRef, useEffect } from "react";
import style from './index.module.less'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import classNames from 'classnames'
import {
    building1,
    building2,
    building3,
    building4,
    building5,
    building6,
    building7
} from '@/images/light'
import { useIntl } from "react-intl"


const Building = () => {
    gsap.registerPlugin(ScrollTrigger)
    const boxRef = useRef()
    const intl = useIntl()
    const list = [
        {
            img: building1,
            title: intl.formatMessage({ id: "light_building_type1_title" }),
            text: intl.formatMessage({ id: "light_building_type1_desc" })
        },
        {
            img: building2,
            title: intl.formatMessage({ id: "light_building_type2_title" }),
            text: intl.formatMessage({ id: "light_building_type2_desc" })
        }, {
            img: building3,
            title: intl.formatMessage({ id: "light_building_type3_title" }),
            text: intl.formatMessage({ id: "light_building_type3_desc" })
        }
        , {
            img: building4,
            title: intl.formatMessage({ id: "light_building_type4_title" }),
            text: intl.formatMessage({ id: "light_building_type4_desc" })
        },
        , {
            img: building5,
            title: intl.formatMessage({ id: "light_building_type5_title" }),
            text: intl.formatMessage({ id: "light_building_type5_desc" })
        },
        {
            img: building6,
            title: intl.formatMessage({ id: "light_building_type6_title" }),
            text: intl.formatMessage({ id: "light_building_type6_desc" })
        },
        {
            img: building7,
            title: intl.formatMessage({ id: "light_building_type7_title" }),
            text: intl.formatMessage({ id: "light_building_type7_desc" })
        }
    ]
    useEffect(() => {
        const boxes = gsap.utils.toArray('.buildingItem');
        boxes.forEach(box => {
            ScrollTrigger.create({
                trigger: box,
                // 顶部与视口中心对齐
                start: "center 70%",
                onEnter: () => {
                    gsap.to(box, {
                        opacity: '1'
                    })
                }
            })
            ScrollTrigger.create({
                trigger: box,
                start: "center bottom",
                onLeaveBack: () => {
                    gsap.to(box, {
                        opacity: '0'
                    })
                }
            })
        });

    }, [])
    return (
        <div className={style.building} >
            <h2 className={style.title}>
                {intl.formatMessage({ id: "light_building_title", defaultMessage: "Building the Future" })}
            </h2>
            <div className={style.buildingBox} ref={boxRef}>
                {list.map((item, index) => (
                    <div key={`buildingItem${index}`} className={style.buildingBoxItem} style={{ backgroundImage: `url(${item.img})` }}>
                        <div className={classNames(style.buildingBoxItemCover, 'buildingItem')}>
                            <h3 className={style.buildingBoxItemCoverTitle}>{item.title}</h3>
                            <div className={style.buildingBoxItemCoverText}> {item.text}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Building