import React from "react";
import style from './index.module.less'
import { useIntl } from "react-intl"

const Specification = ({ list, color }) => {
    const intl = useIntl()

    return (
        <div className={style.spec} style={{ backgroundColor: color ? color : '#fff' }} id='specs'>
            <h2 className={style.specTitle} >

                {intl.formatMessage({ id: "adapter_page4_title", defaultMessage: "Specifications" })}
            </h2>
            <div className={style.specBox}  >
                {list.map((item, index) => (
                    <div key={`spec${index}`} className={style.specBoxItem}>
                        <h3 className={style.specBoxItemTitle}>{item.title}</h3>
                        <div className={style.specBoxItemDec} dangerouslySetInnerHTML={{ __html: `${item.dec}` }}></div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Specification