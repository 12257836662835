import React, { useRef, useEffect } from "react";
import style from './index.module.less'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useBigScreen from "@/hooks/bigScreen"
import classNames from 'classnames'
import {
    dof6Bg, developKit, dof6BgM, fov52,
    developKitM,
} from '@/images/light'

import { useIntl } from "react-intl"

const Power = () => {
    gsap.registerPlugin(ScrollTrigger)
    const powerRef = useRef()
    const powerLeftRef = useRef()
    const powerRight1Ref = useRef()
    const powerRight2Ref = useRef()
    const ifPc = useBigScreen(768)
    const intl = useIntl()
    useEffect(() => {
        const boxes = gsap.utils.toArray('.powerItem');
        if (ifPc) {
            ScrollTrigger.create({
                trigger: powerRef.current,
                start: "bottom bottom",
                onEnter: () => {
                    let tween = gsap.timeline()
                    tween.to(powerLeftRef.current, {
                        opacity: '1',
                        top: 0,
                        duration: '0.4'
                    })
                        .to(powerRight1Ref.current, {
                            opacity: '1',
                            top: 0,
                            duration: "0.4"
                        })
                        .to(powerRight2Ref.current, {
                            opacity: '1',
                            top: 0,
                            duration: "0.4"
                        })
                }
            })
            boxes.forEach(box => {
                ScrollTrigger.create({
                    trigger: powerRef.current,
                    start: "top bottom",
                    onLeaveBack: () => {
                        gsap.to(box, {
                            opacity: '0',
                            top: "100%",
                            duration: "0.3",
                        })
                    }
                })
            })
        } else {
            boxes.forEach(box => {
                ScrollTrigger.create({
                    trigger: box,
                    start: "top bottom",
                    onEnter: () => {
                        gsap.to(box, {
                            opacity: '1',
                            top: 0,
                            duration: '0.6'
                        })
                    }
                })
                ScrollTrigger.create({
                    trigger: powerRef.current,
                    start: "top bottom",
                    onLeaveBack: () => {
                        gsap.to(box, {
                            opacity: '0',
                            top: "100%",
                            duration: "0.3"
                        }, '<')

                    }
                })
            })


        }


    }, [ifPc])
    return (
        <div className={style.box}>
            <h2 className={style.title}>
                {intl.formatMessage({ id: "light_power_title", defaultMessage: "Power in Disguise" })}</h2>
            <div className={style.content} ref={powerRef}>
                <div className={style.contentLeft}>
                    <div className={style.contentLeftBox}>
                        <div className={classNames(style.contentLeftBoxImg, 'powerItem')} style={{ backgroundImage: `url(${fov52})` }} ref={powerLeftRef}>
                            <h2>52° <span>FOV</span></h2>
                        </div>
                    </div>

                </div>
                <div className={style.contentRight}>
                    <div className={style.contentRightBox}>
                        <div className={classNames('powerItem', style.contentRightItem)} style={{ backgroundImage: `url(${ifPc ? dof6Bg : dof6BgM})` }} ref={powerRight1Ref}>
                            <h2> {intl.formatMessage({ id: "light_power_dof", defaultMessage: "6 DOF Tracking" })}</h2>
                        </div>
                    </div>
                    <div className={style.contentRightBox}>
                        <div className={classNames('powerItem', style.contentRightItem)} style={{ backgroundImage: `url(${ifPc ? developKit : developKitM})` }} ref={powerRight2Ref}>
                            <h2>{intl.formatMessage({ id: "light_power_kit", defaultMessage: "Development Kit" })}</h2>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Power