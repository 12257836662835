import React, { useEffect, useRef, useState } from 'react'
import style from './index.module.less'
import { lightTogether } from '@/images/light/index'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useIntl } from 'react-intl';
const Forget = () => {
  const intl = useIntl()
  gsap.registerPlugin(ScrollTrigger)
  const [cover, setCover] = useState(false)
  const boxRef = useRef()
  useEffect(() => {
    let modesTween = gsap.timeline()
    modesTween.to(boxRef.current, {
      scrollTrigger: {
        trigger: boxRef.current,
        start: "bottom bottom",
        onEnter: () => {
          setCover(true)
        },
      }
    })
      .to(boxRef.current, {
        scrollTrigger: {
          trigger: boxRef.current,
          start: "top bottom",
          onLeaveBack: () => { setCover(false) },
        }
      })
  }, [])
  return (
    <div className={style.box} ref={boxRef}>
      <div className={style.boxImg} style={{ backgroundImage: `url(${lightTogether})` }}>
        <h2 className={style.cover} style={{ opacity: cover ? "1" : "0" }}>
          <span>
            {intl.formatMessage({ id: "light_never_forget", defaultMessage: "Some Things We Will Never Forget" })}
          </span>
        </h2>
      </div>
    </div>
  )
}

export default Forget