import lightLogo from './lightLogo.svg'
const lightTogether= `${process.env.resourceUrl}images/light/together.jpg`
const glassesNebula= `${process.env.resourceUrl}images/light/glassesNebula.png`
const developKit= `${process.env.resourceUrl}images/light/developKit.jpg`
const developKitM= `${process.env.resourceUrl}images/light/developKitM.jpg`
const dof6Bg= `${process.env.resourceUrl}images/light/dof6Bg.jpg`
const dof6BgM= `${process.env.resourceUrl}images/light/dof6BgM.jpg`
const building1= `${process.env.resourceUrl}images/light/building1.jpg`
const building2= `${process.env.resourceUrl}images/light/building2.png`
const building3= `${process.env.resourceUrl}images/light/building3.png`
const building4= `${process.env.resourceUrl}images/light/building4.png`
const building5= `${process.env.resourceUrl}images/light/building5.jpg`
const building6= `${process.env.resourceUrl}images/light/building6.jpg`
const building7= `${process.env.resourceUrl}images/light/building7.jpg`
const fov52= `${process.env.resourceUrl}images/light/fov52.jpg`
const lightHeaderBg= `${process.env.resourceUrl}images/light/lightHeaderBg.jpg`
const lightHeaderImg= `${process.env.resourceUrl}images/light/lightHeaderImg.png`




export {
    lightHeaderBg,
    lightHeaderImg,
    fov52,
    dof6BgM,
    developKitM,
    lightLogo,
    lightTogether,
    glassesNebula,
    developKit,
    dof6Bg,
    building1,
    building2,
    building3,
    building4,
    building5,
    building6,
    building7
}