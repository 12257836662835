import React from "react";
import style from './index.module.less'
import { LeftRightBox } from '@/components/index'
import { glassesNebula } from '@/images/light/index'
import { useIntl } from "react-intl"

const Mobile = () => {
    const intl = useIntl()
    const Child = (
        <h2 className={style.child}>
            {intl.formatMessage({ id: "light_mobile_connect", defaultMessage: "World's First AR Glasses to Connect with Mobile Phones" })}
        </h2>
    )
    return (
        <LeftRightBox img={glassesNebula} imgWidth='60%' bgColor='#F1F5F8' direction={false} child={Child} />
    )
}
export default Mobile