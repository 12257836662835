
/**各页面常见排版 左右分别是图片或者文字 移动端上图下字
 * img:图片链接
 * imgWidth：图片大小占比多少
 * child： dom 另一侧内容div
 * bgColor：背景颜色
 * direction：boolean 是左图右字还是左字又图 true为左图右字
 */


import React from "react";
import style from './index.module.less'
import useBigScreen from "@/hooks/bigScreen"

const LeftRightBox = ({ img, imgWidth, child, bgColor, direction }) => {
    const ifPC = useBigScreen(1020)

    return (
        <div className={style.lrBox} style={{ backgroundColor: bgColor, flexDirection: direction ? 'row' : "row-reverse" }}>
            <img src={img} style={{ width: imgWidth }} />
            {child}
        </div>
    )
}

export default LeftRightBox