
/**各页面首屏常见排版 标题+描述+按钮
 * msg:object
 *{
 *     backgroundBg:xxxx,   //背景图片的背景色
 *     background:headerBg, //背景图片
 *     title:{              //标题
 *         type:'img',       //标题类型 img / text
 *        content:airLogo   //内容
 *     },
 *     text:"World's Best Selling Consumer AR Glasses", //描述
 *     btnText:"Buy Now"  //按钮文字
 *     cover:true //是否有蒙版
 *}
 */

import React, { useEffect, useRef, useState } from "react";
import style from './index.module.less'
import { ShiXinBaiBtn_updown } from '@/components/index'
import useBigScreen from "@/hooks/bigScreen"
import useGa from "@/hooks/useGa"
import getCountry from '@/utils/getCountry.js'
import { useIntl } from "react-intl";
import saleIcon from '@/images/icons/sale.svg'
const FirstScreen = ({ msg, amazonLink, amazonSale,shop_sale }) => {
    const intl = useIntl()
    const region = getCountry()
    const boxRef = useRef()
    const bgRef = useRef()
    const bgColorRef = useRef()
    const textRef = useRef()
    const bigScreen = useBigScreen(768)
    return (
        <div className={style.firstScreenBox} ref={boxRef}>
            <div className={style.firstScreenBoxBg} ref={bgColorRef} style={{ backgroundImage: `url(${msg.backgroundBg})` }}>
            </div>
            <div className={style.firstScreenBoxImg} ref={bgRef} style={{ backgroundImage: `url(${msg.background})` }}>
                <div className={style.cover} style={{ backgroundColor: msg.cover ? 'rgba(0, 0, 0, 0.1)' : "transparent" }} ref={textRef}>
                    <div className={style.firstScreenBoxTitle} >
                        {msg.title.type == 'img' ?
                            <img src={msg.title.content} style={{ width: bigScreen ? "240px" : "180px" }} />
                            :
                            <span>{msg.title.content}</span>
                        }
                    </div>
                    <h1 className={style.firstScreenBoxText} >
                        {msg.text}
                    </h1>
                    {msg.btnText && msg.url &&
                        <div className={style.btn}>
                            <a href={msg.url} target="_blank" onClick={() => useGa(msg.cate, `${msg.cate}_head_buy`, 'Click')}>
                                <ShiXinBaiBtn_updown className='firseScreenBtn'>
                                    <div className={style.firstScreenBoxBtn}>
                                        {msg.btnText}
                                    </div>
                                </ShiXinBaiBtn_updown>
                            </a>
                            {(region == 'base' || region == 'jp'||region=='us') && amazonLink && <a href={amazonLink} target="_blank" onClick={() => useGa(msg.cate, `${msg.cate}_head_buy_amazon`, 'Click')}
                                style={{ position: "relative" }}
                            >
                                {!!amazonSale &&( region == 'base'||region=='us') && <img className={style.sale} src={saleIcon} />}
                                <ShiXinBaiBtn_updown className='firseScreenBtn'>
                                    <div className={style.firstScreenBoxBtn}>
                                        {intl.formatMessage({ id: "buy_amazon", defaultMessage: "Buy on Amazon" })}
                                    </div>
                                </ShiXinBaiBtn_updown>
                            </a>}
                        </div>
                    }

                </div>
            </div>



        </div>
    )
}
export default FirstScreen
