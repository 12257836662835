import React from "react";
import { Layout, SEO, FirstScreen, Specification } from '@/components/index'
import { Forget, Power, Mobile, Building } from '@/components/Light/index'
import { lightLogo,
    lightHeaderBg,
    lightHeaderImg, } from '@/images/light/index'
import { lightBanner } from '@/images/experience/index'
import { useIntl } from "react-intl"

const Index = () => {
    const intl = useIntl()
    const firstScreenMsg = {
        background: lightHeaderImg,
        backgroundBg: lightHeaderBg,
        title: {
            type: 'img',
            content: lightLogo
        },
        text: intl.formatMessage({ id: "light_header_text", defaultMessage: "The World's First Lightweight AR Glasses for Developers" }),
        btnText: intl.locale=='ja'?intl.formatMessage({ id: "buy_now", defaultMessage: "Buy Now" }):"",
        url: intl.locale=='ja'?'https://jp.shop.xreal.com/products/xreal-light':"",
        cover: true
    }
    const specList = [
        {
            title: intl.formatMessage({ id: "light_specs_confort", defaultMessage: "Form Factor & Comfort " }),
            dec: intl.formatMessage({ id: "light_specs_confort_text", defaultMessage: "146mm x 44mm x 175mm (opened) , 156mm x 44mm x 52mm (folded),Head width from 135-173mm" })
        },
        {
            title: intl.formatMessage({ id: "light_specs_power", defaultMessage: "Power" }),
            dec: intl.formatMessage({ id: "light_specs_power_text", defaultMessage: "Up to 2.5 hours video streaming in Air Casting mode" })
        },
        {
            title: intl.formatMessage({ id: "light_specs_display", defaultMessage: "Display" }),
            dec: intl.formatMessage({ id: "light_specs_display_text", defaultMessage: "OLED,3840 by 1080 pixels with sRGB 106% color gamut, 8-bit depth for 16.773 million colors , Up to 280 nits perceived brightness, 100,000:1 contrast ratio,~52 degree FoV, 42 pixels per degree (PPD),115\" at 3m (Air Casting Mode), 220\" at 6m (MR Space Mode),Fingerprint resistant coating" })
        },
        {
            title: intl.formatMessage({ id: "light_specs_audio", defaultMessage: "Audio" }),
            dec: intl.formatMessage({ id: "light_specs_audio_text", defaultMessage: "2 open-ear speakers,Dual microphone array, omnidirectional MEMS,microphones" })
        },
        {
            title: intl.formatMessage({ id: "light_specs_human", defaultMessage: "AudiHuman and Environment Understandingo" }),
            dec: intl.formatMessage({ id: "light_specs_human_text", defaultMessage: "6DoF head tracking, hand tracking, image tracking, plane detection" })
        },
        {
            title: intl.formatMessage({ id: "light_specs_control", defaultMessage: "Controls & Sensors" }),
            dec: intl.formatMessage({ id: "light_specs_control_text", defaultMessage: "Screen brightness adjustment button,Accelerometer, gyroscope, magnetometer,Proximity sensor,Ambient light sensor,2 Computer vision cameras, 5MP photo/video camera" })
        },
        {
            title: intl.formatMessage({ id: "light_specs_accesories", defaultMessage: "Accesories" }),
            dec: intl.formatMessage({ id: "light_specs_accesories_text", defaultMessage: "Prescription-lens frame,4 nose pads,Case,Cleaning cloth" })
        }
    ]
    return (
        <Layout footerBackground='#fff' footerTextWhite={false}>
            <SEO page="Light" />
            <FirstScreen msg={firstScreenMsg} />
            <Forget />
            <Power />
            <Mobile />
            <Building />
            <Specification list={specList} color='#f2f2f2' />
        </Layout>

    )
}
export default Index
